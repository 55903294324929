import React from "react";
import { graphql, Link } from "gatsby";
import styled from "styled-components";

const ContentStyles = styled.div`
  margin: 0;
  padding-left: 5vw;
  padding-right: 5vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .space {
    margin-top: 18px;
  }
  .space:last-child {
    margin-bottom: 40px;
  }
  .pic {
    margin-bottom: 10px;
  }
`;

export default function contents({ data }) {
  const stories = data.sections.nodes;
  return (
    <ContentStyles>
      <h1 className="mark">कहानियों का सूची</h1>
      {stories.map((story) => (
        <Link
          className="nav-button space"
          key={story.id}
          to={`/${story.slug.current}`}
        >
          {story.name}
        </Link>
      ))}
    </ContentStyles>
  );
}

export const query = graphql`
  query ContentsQuery {
    sections: allSanitySection(sort: { order: ASC, fields: name }) {
      nodes {
        id
        name
        slug {
          current
        }
      }
    }
  }
`;
